import * as React from "react"
import PropTypes from "prop-types"

import Container from "./container"
import DataTile from "./dataTile"
import ProductTile from "./productTile"

const TilesSection = ( {data, type, location, state} ) => {
  return (
    <Container>
      {type === 'product' &&
        <div className={`${location}-tiles product-tiles-section ${state}`}>
          {data.nodes.map((node) => (
            <ProductTile product={node} location={location} />
          ))}
        </div>
      }
      {type === 'data' &&
        <div className={`${location}-tiles data-tiles-section ${state}`}>
          {data.map((node) => (
            <DataTile entry={node} location={location} />
          ))}
        </div>
      }
    </Container>
  )
}

TilesSection.propTypes = {
  location: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
};

TilesSection.defaultProps = {
  location: '',
  type: '',
  state: '',
};


export default TilesSection