import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import AnimationWrapper from "./animationWrapper"

import "./dataTile.css"

const DataTile = ({ entry, location }) => {

  return (
    <AnimationWrapper animationType="fadeInUp">

    <div className={`data-tile data-tile-${location} ${entry.active ? "active" : ""}`}>

      <div className="data-info-wrapper">
        {entry.name &&
          <div className="data-name">{entry.name}</div>
        }
        {entry.phone &&
          <div className="data-phone">{entry.phone}</div>
        }
        {entry.location &&
          <div className="data-location">{entry.location}</div>
        }
        {entry.specialisations &&
          <div className="data-specialisations-list-wrapper">
            {entry.specialisations.map((specialisation) => (
              <span className="specialisation">{specialisation}</span>
            ))}
          </div>
        }
        {entry.overlay_image &&
          <div className="tile-tip-overlay">
            <GatsbyImage
              image={entry.overlay_image.childImageSharp.gatsbyImageData}
              alt={entry.name}
            />
          </div>
        }
      </div>

    </div>

    </AnimationWrapper>
  )
}

export default DataTile